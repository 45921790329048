.flower {
    position: absolute;
    top: -5vh;
    width: 25px;
    height: 25px;
    z-index: 9999;

    @media (max-width: 768px) {
        width: 15px !important;
        height: 15px !important;
    }
}

@keyframes fallingFlower {
    to {
        transform: translateX(var(--horizontal-drift))
            translateY(var(--main-height)) rotate(var(--rotation));
    }
}

.flower:nth-child(1) {
    left: 67vw;
    --horizontal-drift: -6vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -1s;
}

.flower:nth-child(2) {
    left: 1vw;
    --horizontal-drift: 11vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -2s;
}

.flower:nth-child(3) {
    left: 14vw;
    --horizontal-drift: 5vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -3s;
}

.flower:nth-child(4) {
    left: 59vw;
    --horizontal-drift: 14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -4s;
}

.flower:nth-child(5) {
    left: 77vw;
    --horizontal-drift: 14vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -5s;
}

.flower:nth-child(6) {
    left: 11vw;
    --horizontal-drift: 9vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -6s;
}

.flower:nth-child(7) {
    left: 16vw;
    --horizontal-drift: 12vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -7s;
}

.flower:nth-child(8) {
    left: 44vw;
    --horizontal-drift: 2vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -8s;
}

.flower:nth-child(9) {
    left: 95vw;
    --horizontal-drift: -1vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -9s;
}

.flower:nth-child(10) {
    left: 64vw;
    --horizontal-drift: 11vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -10s;
}

.flower:nth-child(11) {
    left: 50vw;
    --horizontal-drift: 4vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -11s;
}

.flower:nth-child(12) {
    left: 22vw;
    --horizontal-drift: -1vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -12s;
}

.flower:nth-child(13) {
    left: 5vw;
    --horizontal-drift: 13vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -13s;
}

.flower:nth-child(14) {
    left: 84vw;
    --horizontal-drift: -7vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -14s;
}

.flower:nth-child(15) {
    left: 8vw;
    --horizontal-drift: 15vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -15s;
}

.flower:nth-child(16) {
    left: 9vw;
    --horizontal-drift: 8vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -16s;
}

.flower:nth-child(17) {
    left: 33vw;
    --horizontal-drift: -3vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -17s;
}

.flower:nth-child(18) {
    left: 22vw;
    --horizontal-drift: -14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -18s;
}

.flower:nth-child(19) {
    left: 51vw;
    --horizontal-drift: -5vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -19s;
}

.flower:nth-child(20) {
    left: 72vw;
    --horizontal-drift: 14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -20s;
}

.flower:nth-child(21) {
    left: 38vw;
    --horizontal-drift: 6vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -21s;
}

.flower:nth-child(22) {
    left: 17vw;
    --horizontal-drift: 12vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -22s;
}

.flower:nth-child(23) {
    left: 78vw;
    --horizontal-drift: 3vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -23s;
}

.flower:nth-child(24) {
    left: 32vw;
    --horizontal-drift: -3vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -24s;
}

.flower:nth-child(25) {
    left: 19vw;
    --horizontal-drift: 9vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -25s;
}

.flower:nth-child(26) {
    left: 45vw;
    --horizontal-drift: -1vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -26s;
}

.flower:nth-child(27) {
    left: 58vw;
    --horizontal-drift: -9vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -27s;
}

.flower:nth-child(28) {
    left: 59vw;
    --horizontal-drift: 10vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -28s;
}

.flower:nth-child(29) {
    left: 58vw;
    --horizontal-drift: 4vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -29s;
}

.flower:nth-child(30) {
    left: 64vw;
    --horizontal-drift: 15vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -30s;
}

.flower:nth-child(31) {
    left: 46vw;
    --horizontal-drift: -15vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -31s;
}

.flower:nth-child(32) {
    left: 37vw;
    --horizontal-drift: -7vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -32s;
}

.flower:nth-child(33) {
    left: 70vw;
    --horizontal-drift: -5vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -33s;
}

.flower:nth-child(34) {
    left: 86vw;
    --horizontal-drift: 6vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -34s;
}

.flower:nth-child(35) {
    left: 29vw;
    --horizontal-drift: 2vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -35s;
}

.flower:nth-child(36) {
    left: 81vw;
    --horizontal-drift: 12vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -36s;
}

.flower:nth-child(37) {
    left: 36vw;
    --horizontal-drift: -3vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -37s;
}

.flower:nth-child(38) {
    left: 98vw;
    --horizontal-drift: -10vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -38s;
}

.flower:nth-child(39) {
    left: 35vw;
    --horizontal-drift: -4vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -39s;
}

.flower:nth-child(40) {
    left: 85vw;
    --horizontal-drift: 10vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -40s;
}

.flower:nth-child(41) {
    left: 69vw;
    --horizontal-drift: -10vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -41s;
}

.flower:nth-child(42) {
    left: 65vw;
    --horizontal-drift: 9vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -42s;
}

.flower:nth-child(43) {
    left: 25vw;
    --horizontal-drift: -16vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -43s;
}

.flower:nth-child(44) {
    left: 87vw;
    --horizontal-drift: -4vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -44s;
}

.flower:nth-child(45) {
    left: 12vw;
    --horizontal-drift: 9vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -45s;
}

.flower:nth-child(46) {
    left: 37vw;
    --horizontal-drift: -6vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -46s;
}

.flower:nth-child(47) {
    left: 65vw;
    --horizontal-drift: -13vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -47s;
}

.flower:nth-child(48) {
    left: 83vw;
    --horizontal-drift: 4vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -48s;
}

.flower:nth-child(49) {
    left: 47vw;
    --horizontal-drift: 11vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -49s;
}

.flower:nth-child(50) {
    left: 15vw;
    --horizontal-drift: 14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -50s;
}

.flower:nth-child(51) {
    left: 67vw;
    --horizontal-drift: -6vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -51s;
}

.flower:nth-child(52) {
    left: 1vw;
    --horizontal-drift: 11vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -52s;
}

.flower:nth-child(53) {
    left: 14vw;
    --horizontal-drift: 5vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -53s;
}

.flower:nth-child(54) {
    left: 59vw;
    --horizontal-drift: 14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -54s;
}

.flower:nth-child(55) {
    left: 77vw;
    --horizontal-drift: 14vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -55s;
}

.flower:nth-child(56) {
    left: 11vw;
    --horizontal-drift: 9vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -56s;
}

.flower:nth-child(57) {
    left: 16vw;
    --horizontal-drift: -12vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -57s;
}

.flower:nth-child(58) {
    left: 44vw;
    --horizontal-drift: 2vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -58s;
}

.flower:nth-child(59) {
    left: 95vw;
    --horizontal-drift: -1vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -59s;
}

.flower:nth-child(60) {
    left: 64vw;
    --horizontal-drift: 11vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -60s;
}

.flower:nth-child(61) {
    left: 50vw;
    --horizontal-drift: 4vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -61s;
}

.flower:nth-child(62) {
    left: 22vw;
    --horizontal-drift: -1vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -62s;
}

.flower:nth-child(63) {
    left: 5vw;
    --horizontal-drift: 13vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -63s;
}

.flower:nth-child(64) {
    left: 84vw;
    --horizontal-drift: -7vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -64s;
}

.flower:nth-child(65) {
    left: 8vw;
    --horizontal-drift: 15vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -65s;
}

.flower:nth-child(66) {
    left: 9vw;
    --horizontal-drift: -8vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -66s;
}

.flower:nth-child(67) {
    left: 33vw;
    --horizontal-drift: -3vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -67s;
}

.flower:nth-child(68) {
    left: 22vw;
    --horizontal-drift: -14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -68s;
}

.flower:nth-child(69) {
    left: 51vw;
    --horizontal-drift: -5vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -69s;
}

.flower:nth-child(70) {
    left: 72vw;
    --horizontal-drift: 14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -70s;
}

.flower:nth-child(71) {
    left: 38vw;
    --horizontal-drift: 6vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -71s;
}

.flower:nth-child(72) {
    left: 17vw;
    --horizontal-drift: 12vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -72s;
}

.flower:nth-child(73) {
    left: 78vw;
    --horizontal-drift: 3vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -73s;
}

.flower:nth-child(74) {
    left: 32vw;
    --horizontal-drift: -3vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -74s;
}

.flower:nth-child(75) {
    left: 19vw;
    --horizontal-drift: 9vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -75s;
}

.flower:nth-child(76) {
    left: 45vw;
    --horizontal-drift: -1vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -76s;
}

.flower:nth-child(77) {
    left: 58vw;
    --horizontal-drift: -9vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -77s;
}

.flower:nth-child(78) {
    left: 59vw;
    --horizontal-drift: 10vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -78s;
}

.flower:nth-child(79) {
    left: 58vw;
    --horizontal-drift: 4vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -79s;
}

.flower:nth-child(80) {
    left: 64vw;
    --horizontal-drift: 15vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -80s;
}

.flower:nth-child(81) {
    left: 46vw;
    --horizontal-drift: -15vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -81s;
}

.flower:nth-child(82) {
    left: 37vw;
    --horizontal-drift: -7vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -82s;
}

.flower:nth-child(83) {
    left: 70vw;
    --horizontal-drift: -5vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -83s;
}

.flower:nth-child(84) {
    left: 86vw;
    --horizontal-drift: 6vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -84s;
}

.flower:nth-child(85) {
    left: 29vw;
    --horizontal-drift: 2vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -85s
}

.flower:nth-child(86) {
    left: 81vw;
    --horizontal-drift: 12vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -86s;
}

.flower:nth-child(87) {
    left: 36vw;
    --horizontal-drift: -3vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -87s;
}

.flower:nth-child(88) {
    left: 98vw;
    --horizontal-drift: -10vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -88s;
}

.flower:nth-child(89) {
    left: 35vw;
    --horizontal-drift: -4vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -89s;
}

.flower:nth-child(90) {
    left: 85vw;
    --horizontal-drift: 10vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -90s;
}

.flower:nth-child(91) {
    left: 69vw;
    --horizontal-drift: -10vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -91s;
}

.flower:nth-child(92) {
    left: 65vw;
    --horizontal-drift: 9vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -92s;
}

.flower:nth-child(93) {
    left: 25vw;
    --horizontal-drift: -16vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -93s;
}

.flower:nth-child(94) {
    left: 87vw;
    --horizontal-drift: -4vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -94s;
}

.flower:nth-child(95) {
    left: 12vw;
    --horizontal-drift: 9vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -95s;
}

.flower:nth-child(96) {
    left: 37vw;
    --horizontal-drift: -6vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -96s;
}

.flower:nth-child(97) {
    left: 65vw;
    --horizontal-drift: -13vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -97s;
}

.flower:nth-child(98) {
    left: 83vw;
    --horizontal-drift: 4vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -98s;
}

.flower:nth-child(99) {
    left: 47vw;
    --horizontal-drift: 11vw;
    --rotation: -1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -99s;
}

.flower:nth-child(100) {
    left: 15vw;
    --horizontal-drift: 14vw;
    --rotation: 1440deg;
    animation: fallingFlower var(--duration) linear infinite;
    animation-delay: -100s;
}
